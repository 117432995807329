<template>
    <div>
      <a href="https://apps.apple.com/us/app/crafty-lands/id1451992102" target="_blank">
        <svg width="100%" viewBox="0 0 146 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
          <path d="M145.045 37.0918C145.045 39.3858 143.203 41.1895 140.905 41.1895H4.90052C2.60469 41.1914 0.859497 39.4389 0.859497 37.1449V4.03919C0.859497 1.7452 2.60469 0 4.90052 0H140.905C143.203 0 145.025 1.7452 145.025 4.03919V37.1467L145.045 37.0918Z" fill="#1C1A16"/>
          <path d="M32.4322 20.3608C32.3992 16.8686 35.2933 15.1673 35.425 15.0886C33.7877 12.6995 31.2486 12.3739 30.3559 12.3483C28.2247 12.1251 26.1557 13.6252 25.0709 13.6252C23.9641 13.6252 22.2866 12.3702 20.4865 12.4068C18.1669 12.4452 15.9955 13.7861 14.8064 15.8716C12.3514 20.123 14.1826 26.372 16.5351 29.8112C17.7132 31.4942 19.0889 33.3729 20.889 33.3071C22.6488 33.2339 23.3074 32.1857 25.4331 32.1857C27.5405 32.1857 28.1588 33.3071 29.9936 33.265C31.8834 33.2339 33.0724 31.5747 34.2085 29.8752C35.5713 27.9471 36.1165 26.0464 36.1384 25.9476C36.0945 25.933 32.4687 24.55 32.4322 20.3608Z" fill="white"/>
          <path d="M28.9637 10.087C29.9113 8.9034 30.5608 7.28991 30.3778 5.65448C29.0076 5.71485 27.2935 6.60208 26.3075 7.76188C25.4331 8.78266 24.652 10.4565 24.8532 12.0297C26.3953 12.145 27.9741 11.2541 28.9637 10.087Z" fill="white"/>
          <path d="M50.3927 13.5948L45.7461 28.021H48.1426L49.4286 23.7824H54.1136L55.4636 28.021H57.9241L53.2593 13.5948H50.3927ZM49.8329 22.0043L51.0311 18.2377C51.3439 17.0413 51.5744 16.1358 51.7171 15.5211H51.7592C52.1159 16.9462 52.3574 17.8535 52.4873 18.2377L53.7056 22.0043H49.8329Z" fill="white"/>
          <path d="M65.5652 17.4028C63.9975 17.4028 62.8249 18.0229 62.0566 19.265H62.0127L61.8828 17.6168H59.8504C59.9071 18.7876 59.9345 19.9291 59.9345 21.0413V32.2552H62.2468V26.7763H62.2871C62.8871 27.7605 63.8713 28.2526 65.2414 28.2526C66.5092 28.2526 67.5739 27.7971 68.4282 26.8843C69.3849 25.8562 69.8624 24.4586 69.8624 22.6896C69.8624 21.1072 69.4526 19.8321 68.633 18.8589C67.8153 17.8894 66.7891 17.4028 65.5652 17.4028ZM66.7836 25.4299C66.3006 26.1013 65.6219 26.4342 64.7512 26.4342C64.0103 26.4342 63.4066 26.1763 62.942 25.6531C62.4791 25.1336 62.2486 24.4951 62.2486 23.7396V22.0054C62.2486 21.8206 62.2834 21.5865 62.3547 21.3011C62.4974 20.6864 62.8011 20.1925 63.2639 19.812C63.7249 19.437 64.2444 19.2449 64.8152 19.2449C65.6549 19.2449 66.3262 19.5888 66.8257 20.273C67.2812 20.9297 67.5098 21.7639 67.5098 22.7774C67.5117 23.8732 67.2702 24.7567 66.7836 25.4299Z" fill="white"/>
          <path d="M77.5291 17.4028C75.9577 17.4028 74.7888 18.0229 74.0205 19.265H73.9766L73.8467 17.6168H71.8143C71.8692 18.7876 71.8984 19.9291 71.8984 21.0413V32.2552H74.2089V26.7763H74.251C74.851 27.7605 75.8333 28.2526 77.2035 28.2526C78.4749 28.2526 79.5359 27.7971 80.3921 26.8843C81.3488 25.8562 81.8263 24.4586 81.8263 22.6896C81.8263 21.1072 81.4183 19.8321 80.597 18.8589C79.7792 17.8894 78.7548 17.4028 77.5291 17.4028ZM78.7475 25.4299C78.2645 26.1013 77.584 26.4342 76.7151 26.4342C75.9705 26.4342 75.3687 26.1763 74.9059 25.6531C74.4412 25.1336 74.2125 24.4951 74.2125 23.7396V22.0054C74.2125 21.8206 74.2473 21.5865 74.3205 21.3011C74.4632 20.6864 74.765 20.1925 75.2278 19.812C75.6907 19.437 76.2084 19.2449 76.7791 19.2449C77.6206 19.2449 78.2901 19.5888 78.7896 20.273C79.2469 20.9297 79.4756 21.7639 79.4756 22.7774C79.4756 23.8732 79.2304 24.7567 78.7475 25.4299Z" fill="white"/>
          <path d="M91.5895 19.7149C90.5761 19.3143 89.8754 18.9429 89.4913 18.6008C89.0486 18.2313 88.8291 17.7593 88.8291 17.1904C88.8291 16.6782 89.023 16.241 89.4053 15.8843C89.8608 15.4836 90.4956 15.2842 91.3078 15.2842C92.3359 15.2842 93.2561 15.5056 94.0701 15.9464L94.6464 14.0622C93.7774 13.6067 92.6853 13.3762 91.3737 13.3762C89.9029 13.3762 88.7266 13.7567 87.8431 14.5141C86.9577 15.2696 86.5168 16.2428 86.5168 17.4282C86.5168 19.2429 87.7772 20.6223 90.3035 21.5662C91.231 21.9101 91.8969 22.2796 92.2957 22.6821C92.6945 23.0809 92.8939 23.5766 92.8939 24.1602C92.8939 24.8169 92.6524 25.342 92.1676 25.7371C91.681 26.1286 91.0042 26.3243 90.1334 26.3243C88.9205 26.3243 87.8065 26.0188 86.7912 25.4042L86.2552 27.3305C87.1973 27.9287 88.4431 28.2287 89.9962 28.2287C91.6755 28.2287 92.9835 27.8097 93.9238 26.9664C94.7817 26.1944 95.2061 25.1974 95.2061 23.97C95.2061 22.9711 94.9134 22.137 94.3299 21.4656C93.7427 20.7997 92.8298 20.2143 91.5895 19.7149Z" fill="white"/>
          <path d="M100.299 15.0263L98.0306 15.7123V17.6148H96.5104V19.349H98.0306V24.5938C98.0306 25.9054 98.2886 26.8421 98.8008 27.3982C99.313 27.9543 100.028 28.2305 100.941 28.2305C101.682 28.2305 102.284 28.1464 102.738 27.9744L102.674 26.2201C102.403 26.2896 102.059 26.3262 101.647 26.3262C100.747 26.3262 100.297 25.6841 100.297 24.3999V19.349H102.844V17.6148H100.297V15.0263H100.299Z" fill="white"/>
          <path d="M109.33 17.4028C107.746 17.4028 106.488 17.915 105.555 18.9449C104.618 19.9712 104.153 21.2901 104.153 22.9036C104.153 24.4421 104.605 25.719 105.513 26.7324C106.42 27.7459 107.633 28.2526 109.162 28.2526C110.743 28.2526 112.014 27.7258 112.971 26.6702C113.884 25.6421 114.339 24.3305 114.339 22.7317C114.339 21.1767 113.898 19.909 113.013 18.923C112.085 17.9095 110.856 17.4028 109.33 17.4028ZM111.299 25.2964C110.801 26.1416 110.108 26.5623 109.226 26.5623C108.324 26.5623 107.626 26.147 107.126 25.3183C106.711 24.6323 106.508 23.811 106.508 22.8524C106.508 21.8645 106.713 21.0267 107.126 20.3407C107.609 19.5102 108.315 19.0949 109.245 19.0949C110.143 19.0949 110.836 19.5102 111.319 20.3407C111.734 21.0285 111.941 21.8499 111.941 22.8085C111.941 23.7671 111.727 24.5958 111.299 25.2964Z" fill="white"/>
          <path d="M119.604 17.9605C119.077 18.3739 118.691 18.9246 118.448 19.6087H118.386L118.302 17.6166H116.289C116.344 18.6008 116.373 19.6929 116.373 20.8929L116.353 28.0182H118.664V22.5613C118.664 21.7344 118.856 21.0503 119.24 20.5069C119.683 19.8941 120.314 19.5849 121.124 19.5849C121.38 19.5849 121.622 19.6069 121.851 19.649V17.4446C121.68 17.419 121.486 17.4044 121.274 17.4044C120.674 17.4025 120.116 17.5891 119.604 17.9605Z" fill="white"/>
          <path d="M132.186 22.3254C132.186 21.0266 131.881 19.9363 131.27 19.0509C130.498 17.9386 129.362 17.3825 127.864 17.3825C126.338 17.3825 125.125 17.9386 124.227 19.0509C123.369 20.0936 122.941 21.3997 122.941 22.9675C122.941 24.5645 123.404 25.8414 124.32 26.7872C125.24 27.7366 126.508 28.2122 128.118 28.2122C129.461 28.2122 130.624 27.9964 131.606 27.5683L131.244 25.9621C130.401 26.2896 129.464 26.4542 128.442 26.4542C127.514 26.4542 126.755 26.2146 126.173 25.7298C125.531 25.1865 125.195 24.4017 125.169 23.3736H132.102C132.159 23.0882 132.186 22.7407 132.186 22.3254ZM125.171 21.7254C125.24 21.0266 125.467 20.4284 125.853 19.929C126.323 19.3015 126.938 18.9868 127.692 18.9868C128.518 18.9868 129.135 19.3088 129.534 19.9509C129.847 20.4503 129.998 21.043 129.982 21.7272H125.171V21.7254Z" fill="white"/>
        </g>
        <defs>
          <clipPath id="clip0">
            <rect x="0.859497" width="144.185" height="41.1914" fill="white"/>
          </clipPath>
        </defs>
        </svg>
      </a>
    </div>
</template>

<script>
export default {
  name: 'app-store'
}
</script>
