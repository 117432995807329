import account from '@/faster/account'

const actions = new function() {
  this.forgot = async (context, { key }) => {
    try {
      await account.email.resetPassword(key)
    } catch (err) {
      throw new Error(err.code, { cause: err })
    }
  }

  this.change = async (context, { actionToken, newPassword }) => {
    try {
      await account.email.changePassword(actionToken, newPassword)
    } catch (err) {
      throw new Error(err.message)
    }
  }
}()

export default {
  namespaced: true,
  actions
}
