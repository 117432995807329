import client from '@/store/modules/clients/unauthenticatedHttpClient'

const actions = new function() {
  this.confirmDelete = async (context, token) => {
    const response = await client.post('/v1/privacy/delete/confirm', { token: token, application: 'LANDS' })
    if (response.ok) {
      return
    }

    const error = await response.json()
    throw new Error(error.code)
  }

  this.confirmDataRequest = async (context, token) => {
    const response = await client.post('/v1/privacy/data/confirm', { token, application: 'LANDS' })
    if (response.ok) {
      return
    }

    const error = await response.json()
    throw new Error(error.statusCode)
  }
}()

export default {
  namespaced: true,
  actions
}
