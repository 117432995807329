import Vue from 'vue'
import IconSettings from '@/assets/images/icons/settings.svg?inline'
import IconLogout from '@/assets/images/icons/logout.svg?inline'

const components = {
  IconSettings,
  IconLogout
}

Object.keys(components).forEach(item => {
  Vue.component(item, components[item])
})
