export default {
  description: '¡Bienvenido a tu mundo virtual! Crafty Lands es pura diversión: crea tu propio avatar, construye una casa, reúnete con tus amigos y vive nuevas aventuras.',
  passwordReset: {
    titles: {
      default: 'Contraseña nueva',
      success: '¡La nueva contraseña se configuró correctamente!',
      error: 'Algo salió mal'
    },
    subtitles: {
      default: 'Ingresa una contraseña nueva para tu cuenta de Crafty Lands.',
      success: '¡La nueva contraseña se configuró correctamente!',
      error: 'Parece que se ha producido un error. Inténtalo de nuevo.'
    },
    placeholder: '(De 6 a 15 caracteres)',
    labels: {
      newPassword: 'Contraseña nueva',
      confirmPassword: 'Confirmar contraseña'
    },
    buttons: {
      save: 'GUARDAR'
    },
    errors: {
      size: 'Ingresa una contraseña válida que tenga de 6 a 15 caracteres',
      mismatch: 'Estas contraseñas no coinciden. Inténtalo de nuevo.'
    }
  },
  passwordForgot: {
    titles: {
      default: '¿Olvidaste tu contraseña?',
      success: 'Recibimos tu solicitud',
      error: 'Algo salió mal'
    },
    subtitles: {
      default: 'Ingresa tu dirección de correo electrónico y te enviaremos instrucciones sobre cómo cambiar tu contraseña.',
      success: 'Si el correo electrónico anterior está registrado en Crafty Lands, recibirás instrucciones sobre cómo cambiar tu contraseña por correo electrónico. Revisa tu bandeja de entrada y sigue las instrucciones.',
      error: {
        nicknameNotFound: 'No se encontró el nombre de usuario',
        emailNotFound: 'No se encontró el correo electrónico',
        noEmailAssociated: 'Esta cuenta no tiene un correo electrónico registrado',
        default: 'Parece que se ha producido un error. Inténtalo de nuevo.'
      }
    },
    buttons: {
      send: 'ENVIAR'
    },
    labels: {
      key: 'correo electrónico'
    },
    errors: {
      invalidKey: 'Ingresa una dirección de correo electrónico válida.'
    }
  },
  login: {
    username: 'Nombre de usuario',
    password: 'Contraseña',
    email: 'Email',
    forgotPassword: 'Olvide mi contraseña',
    backToLogin: 'Volver a inicio de sesión',
    didNotGetEmail: '¿No recibiste el correo?',
    titles: {
      forgotPassword: '¿Olvidaste tu contraseña?'
    },
    subtitles: {
      enterPassword: 'Ingresa tu dirección de correo electrónico y te enviaremos instrucciones sobre cómo cambiar tu contraseña.',
      emailSent: 'Te enviamos un correo electrónico. Revísalo :)'
    },
    buttons: {
      login: 'Login',
      send: 'ENVIAR'
    },
    policies: {
      privacy: 'Política de privacidad',
      terms: 'Términos de uso'
    }
  },
  general: {
    success: 'Satisfactorio',
    tryAgain: 'Inténtalo de nuevo'
  },
  navbar: {
    menu: {
      store: 'Tienda',
      editAvatar: 'Editar avatar'
    },
    dropdown: {
      logout: 'Logout'
    },
    alert: {
      text: 'Solicitaste la revocación de tu consentimiento. Tu cuenta será eliminada dentro de 14 días',
      cancel: 'CANCELAR'
    }
  },
  inventory: {
    buttons: {
      save: 'GUARDAR'
    }
  },
  screenshot: {
    share: 'Compartir en Facebook'
  },

  privacy: {
    info: {
      title: 'Centro de privacidad',
      text: 'La información personal es información permite identificar a una persona. En Crafty Lands, nos tomamos muy en serio la protección de tu información personal. Respetamos todas las exigencias legales y tomamos todas las medidas técnicas y organizativas necesarias para garantizar la protección de tu información.'
    },
    faq: {
      title: 'FAQ',
      issues: [
        {
          id: 'treat_personal_data',
          title: '¿Crafty Lands utiliza mi información personal?',
          content: [
            'Cuando creas una cuenta en Crafty Lands, proporcionas tu correo electrónico y nombre de usuario a nuestra base de datos. Cuando juegas, tus datos de acceso quedan almacenados y, cuando realizas una compra, tus datos de facturación también.'
          ],
          'isExpanded': false,
          'isHome': true,
          'isActive': true
        },
        {
          id: 'get_personal_data',
          title: '¿Qué información personal sobre mí posee Crafty Lands?',
          content: [
            'En Crafty Lands recopilamos el correo con el que creaste tu cuenta y tus datos de acceso (IP, fecha y hora) para mejorar tu experiencia. Cuando quieras, puedes pedirnos que te enviemos un archivo con toda la información personal que almacenamos sobre ti. Lo recibirás en tu correo en un plazo de 15 días.',
            { type: 'action', value: 'Haz clic aquí para descargar tu información personal' }
          ],
          'isExpanded': false,
          'isHome': true,
          'isActive': true
        },
        {
          id: 'att_data',
          title: '¿Cómo actualizo mi información personal?',
          content: [
            'Para actualizar tu información personal, haz clic en el enlace que sigue. Recibirás un correo con las instrucciones.',
            { type: 'link', value: 'Haz clic aquí para actualizar tu información personal.', href: 'https://craftylands.zendesk.com/hc/pt-br/requests/new' }
          ],
          'isExpanded': false,
          'isHome': true,
          'isActive': true
        },
        {
          id: 'privacy_policy',
          title: '¿Con quién comparten mi información personal?',
          content: [
            'Para ofrecerte un juego cada vez mejor, contamos con una red de socios, que utilizan algunos de tus datos para optimizar nuestros servidores, mejorar tu experiencia dentro del juego y mostrar anuncios seguros y relevantes. Todo esto está explicado en nuestra política de privacidad.',
            { type: 'link', value: 'política de privacidad.', href: 'https://policies.craftylands.afterverse.com/privacy/2.0' }
          ],
          'isExpanded': false,
          'isHome': true,
          'isActive': true
        },
        {
          id: 'revoke',
          title: '¿Puedo revocar mi consentimiento para el tratamiento de información personal?',
          content: [
            'Necesitamos tu consentimiento para recopilar algunos de tus datos cuando creas una cuenta o juegas. Puedes revocar tu consentimiento en cualquier momento. Atención: si revocas tu consentimiento, tu cuenta será eliminada de manera permanente en 14 días y perderás todos los objetos adquiridos dentro del juego, sin posibilidad de recuperarlos.',
            { type: 'action', value: 'Haz clic aquí para revocar tu consentimiento.' }
          ],
          email_sent_content: [
            'Necesitamos tu consentimiento para recopilar algunos de tus datos cuando creas una cuenta o juegas. Puedes revocar tu consentimiento en cualquier momento. Atención: si revocas tu consentimiento, tu cuenta será eliminada de manera permanente en 14 días y perderás todos los objetos adquiridos dentro del juego, sin posibilidad de recuperarlos.',
            { type: 'warn', value: 'Te enviamos un correo con un enlace para confirmar tu solicitud. Si no recibiste el correo, revisa la bandeja de spam/correo no deseado o haz clic aquí para que te lo reenviemos.' },
            { type: 'action', value: 'Reenviar correo' }
          ],
          email_confirmed_content: [
            'Necesitamos tu consentimiento para recopilar algunos de tus datos cuando creas una cuenta o juegas. Puedes revocar tu consentimiento en cualquier momento. Atención: si revocas tu consentimiento, tu cuenta será eliminada de manera permanente en 14 días y perderás todos los objetos adquiridos dentro del juego, sin posibilidad de recuperarlos.',
            { type: 'warn', value: 'Solicitaste la revocación de tu consentimiento. Tu cuenta será eliminada dentro de 14 días' },
            { type: 'action', value: 'Cancelar' }
          ],
          'isExpanded': false,
          'isHome': true,
          'isActive': true
        },
        {
          id: 'delete',
          title: '¿Cómo elimino mi información personal de su base de datos?',
          content: [
            'Puedes solicitar la eliminación de toda tu información personal de nuestra base de datos en cualquier momento. Atención: si eliminas tu información de nuestra base de datos, tu cuenta será eliminada de manera permanente en 14 días y perderás todos los objetos adquiridos dentro del juego, sin posibilidad de recuperarlos.',
            { type: 'action', value: 'Clique aqui para excluir seus información personal' }
          ],
          email_sent_content: [
            'Puedes solicitar la eliminación de toda tu información personal de nuestra base de datos en cualquier momento. Atención: si eliminas tu información de nuestra base de datos, tu cuenta será eliminada de manera permanente en 14 días y perderás todos los objetos adquiridos dentro del juego, sin posibilidad de recuperarlos.',
            { type: 'warn', value: 'Te enviamos un correo con un enlace para confirmar tu solicitud. Si no recibiste el correo, revisa la bandeja de spam/correo no deseado o haz clic aquí para que te lo reenviemos.' },
            { type: 'action', value: 'Reenviar correo' }
          ],
          email_confirmed_content: [
            'Puedes solicitar la eliminación de toda tu información personal de nuestra base de datos en cualquier momento. Atención: si eliminas tu información de nuestra base de datos, tu cuenta será eliminada de manera permanente en 14 días y perderás todos los objetos adquiridos dentro del juego, sin posibilidad de recuperarlos.',
            { type: 'warn', value: 'Solicitaste la revocación de tu consentimiento. Tu cuenta será eliminada detro de 14 días' },
            { type: 'action', value: 'Cancelar' }
          ],
          'isExpanded': false,
          'isHome': true,
          'isActive': true
        },
        {
          id: 'ads',
          title: 'Anuncios personalizados',
          content: [
            'Para que en Crafty Lands podamos tener una infraestructura y un equipo dedicados a las novedades y mejoras, parte de las ganancias del juego provienen de anuncios personalizados y relevantes para cada jugador. Puedes optar por no recibir estos anuncios y recibir solo anuncios para el público en general.'
          ],
          'isExpanded': false,
          'isHome': true,
          'isActive': true
        },
        {
          id: 'terms&privacy',
          title: 'Términos de uso y política de privacidad',
          content: [
            'Nuestros términos de uso y nuestra política de privacidad están disponibles para que los consultes cuando quieras. Te informaremos si hay alguna alteración en el tratamiento de tu información.',
            { type: 'link', value: 'Términos de uso', href: 'https://policies.craftylands.afterverse.com/en/terms/2.0' },
            { type: 'link', value: 'Política de privacidad', href: 'https://policies.craftylands.afterverse.com/privacy/2.0' }
          ],
          'isExpanded': false,
          'isHome': true,
          'isActive': true
        }
      ]
    },
    modals: {
      closeText: 'CANCELAR',
      confirmText: 'OK',
      delete: {
        title: 'Eliminar información personal',
        warning: '¿Estás seguro de que quieres eliminar tu información personal? Si lo haces, tu cuenta será eliminada y perderás todo el progreso y todas las compras que hayas hecho dentro del juego.',
        email: 'Te enviamos un correo con un enlace para confirmar tu solicitud. Si no recibiste el correo, revisa la bandeja de spam/correo no deseado o haz clic aquí para que te lo reenviemos.',
        buttonText: 'ELIMINAR'
      },
      revoke: {
        title: 'Revocar consentimiento',
        warning: '¿Estás seguro de que quieres revocar tu consentimiento? Si lo haces, tu cuenta será eliminada y perderás todo el progreso y todas las compras que hayas hecho dentro del juego.',
        email: 'Te enviamos un correo con un enlace para confirmar tu solicitud. Si no recibiste el correo, revisa la bandeja de spam/correo no deseado o haz clic aquí para que te lo reenviemos.',
        buttonText: 'REVOCAR'
      },
      request: {
        title: 'Solicitar información personal',
        email: 'Te enviamos un correo con un enlace para confirmar tu solicitud. Si no recibiste el correo, revisa la bandeja de spam/correo no deseado o haz clic aquí para que te lo reenviemos.'
      },
      error: {
        title: 'Oops',
        text: 'Parece que algo salió mal. Vuelve a intentarlo.'
      },
      captcha: {
        text: 'Haga clic en el botón de abajo para asegurarse de que no es un robot:',
        label: 'No soy un robot'
      }
    },
    confirmationViews: {
      delete: {
        title: 'Eliminación de datos solicitada',
        text: 'Tu información personal será eliminada en 14 días. Cuando eso suceda, tu cuenta será eliminada de forma permanente. Haz clic aquí para revertirlo craftylands.afterverse.com'
      },
      request: {
        title: 'Dados solicitados',
        text: 'Solicitaste tu información personal, dentro de los próximos 14 días te enviaremos un correo con tus datos. Si tienes alguna duda, haz clic aquí craftylands.afterverse.com'
      },
      error: {
        title: 'Oops, parece que su solicitud ha caducado',
        text: 'No pudimos completar la operación porque su solicitud ha caducado, puede solicitar la operación nuevamente a través del centro de privacidad'
      },
      loading: {
        title: 'Solicitud de procesamiento',
        text: 'Estamos procesando su solicitud, por favor espere'
      }
    }
  }
}
