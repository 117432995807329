import client from '@/store/modules/clients/authenticatedHttpClient'

const state = {
  privacyState: 'DEFAULT'
}

const actions = new function() {
  this.scheduleDelete = async (context) => {
    const response = await client.post('/v1/privacy/delete/schedule/new', { application: 'LANDS' })
    if (response.ok) {
      return
    }

    const error = await response.json()
    throw new Error(error.code)
  }

  this.requestData = async (context) => {
    const response = await client.post('/v1/privacy/data/request', { application: 'LANDS' })
    if (response.ok) {
      return
    }

    const error = await response.json()
    throw new Error(error.code)
  }

  this.findSchedules = async (context) => {
    const response = await client.get('/v1/privacy/delete/schedule')
    if (response.ok) {
      return response.json()
    }

    const error = await response.json()
    throw new Error(error.code)
  }

  this.cancelSchedule = async (context) => {
    const response = await client.post('/v1/privacy/delete/schedule/cancel', {})
    if (response.ok) {
      return
    }

    const error = await response.json()
    throw new Error(error.code)
  }

  this.privacyState = async ({ commit }) => {
    const schedules = await this.findSchedules()
    let privacyState = 'DEFAULT'
    if (schedules.length > 0) {
      privacyState = schedules[0].status
    }
    commit('SET_PRIVACY_STATE', privacyState)
  }
}()

const mutations = {
  SET_PRIVACY_STATE(state, privacyState) {
    state.privacyState = privacyState
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
