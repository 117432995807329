const HOME_ROUTE = { name: 'home' }
const STORE_ROUTE = { name: 'store' }
const CHECKOUT_ROUTE = { name: 'checkout' }
const CHECKOUT_SUCCESS_ROUTE = { name: 'checkoutSuccess', path: '/checkout-success' }
const CHECKOUT_COLLECT_ROUTE = { name: 'checkoutCollect', path: '/checkout-collect' }
const AVATAR_ROUTE = { name: 'avatar' }
const LOGIN_ROUTE = { name: 'login' }
const PASSWORD_FORGOT_ROUTE = { name: 'passwordForgot' }
const PASSWORD_RESET_ROUTE = { name: 'passwordReset' }
const PRIVACY_ROUTE = { name: 'privacy' }
const CONFIRM_PRIVACY_ROUTE = { name: 'ConfirmationEmailPrivacy' }

export {
  HOME_ROUTE,
  STORE_ROUTE,
  CHECKOUT_ROUTE,
  CHECKOUT_SUCCESS_ROUTE,
  CHECKOUT_COLLECT_ROUTE,
  AVATAR_ROUTE,
  LOGIN_ROUTE,
  PASSWORD_FORGOT_ROUTE,
  PASSWORD_RESET_ROUTE,
  PRIVACY_ROUTE,
  CONFIRM_PRIVACY_ROUTE
}
