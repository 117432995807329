import Vue from 'vue'
import VueRouter from 'vue-router'
import {
  HOME_ROUTE,
  LOGIN_ROUTE,
  PASSWORD_FORGOT_ROUTE,
  PASSWORD_RESET_ROUTE,
  PRIVACY_ROUTE,
  CONFIRM_PRIVACY_ROUTE
} from './router-types'
import store from '@/store'
import Login from '../views/Login.vue'
import Privacy from '../views/Privacy.vue'
import Home from '../views/Home.vue'
import PasswordForgot from '../views/PasswordForgot.vue'
import PasswordReset from '../views/PasswordReset.vue'
import ConfirmationEmail from '../views/ConfirmationEmail.vue'
import eventEmitter from '@/faster/events/emitter'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: {
      ...HOME_ROUTE
    }
  },
  {
    ...HOME_ROUTE,
    path: '/home',
    redirect: PRIVACY_ROUTE.name,
    component: Home,
    beforeEnter: (to, from, next) => {
      store.dispatch('privacy/privacyState')
      next()
    },
    meta: {
      authenticationGuard: true
    },
    children: [
      {
        ...PRIVACY_ROUTE,
        path: '/privacy',
        component: Privacy,
        meta: {
          authenticationGuard: true
        }
      }
    ]
  },
  {
    ...LOGIN_ROUTE,
    path: '/login',
    component: Login
  },
  {
    ...PASSWORD_FORGOT_ROUTE,
    path: '/forgot-password',
    component: PasswordForgot
  },
  {
    ...PASSWORD_RESET_ROUTE,
    path: '/password-reset',
    component: PasswordReset
  },
  {
    ...CONFIRM_PRIVACY_ROUTE,
    props: (route) => ({ query: { token: route.query.token } }),
    path: '/confirm/:operation',
    component: ConfirmationEmail
  }
]

const scrollBehavior = (to, from, savedPosition) => {
  const position = savedPosition || { x: 0, y: 0 }

  return position
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior
})

const authentication = {
  createUserSession: () => store.dispatch('authentication/createUserSession')
}

async function hasUserSession() {
  await authentication.createUserSession()
  return store.state.authentication.authenticated
}

router.beforeEach(async (to, from, next) => {
  eventEmitter.screen(to.name)

  if (to.meta.authenticationGuard) {
    if (!store.state.authentication.authenticated) {
      if (await hasUserSession()) {
        next()
      } else {
        next(LOGIN_ROUTE)
      }
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
