<template>
  <div class="layout-default__body layout-default__body--privacy">
    <div class="layout-default__body__main__container layout-default__body__main__container--privacy email-confirmation">
      <confirmation-email-card :title="title" :text="text" />
    </div>
  </div>
</template>

<script>
import ConfirmationEmailCard from '../components/ConfirmationEmailCard.vue'
import { mapActions } from 'vuex'

export default {
  components: { ConfirmationEmailCard },
  name: 'ConfirmationEmail',
  data: () => ({
    error: false,
    loading: false
  }),
  methods: {
    ...mapActions({
      confirmDeleteRequest: 'confirmationEmail/confirmDelete',
      confirmDataRequest: 'confirmationEmail/confirmDataRequest'
    }),

    async confirmDelete() {
      this.loading = true
      try {
        await this.confirmDeleteRequest(this.token)
      } catch (err) {
        this.error = true
      }
      this.loading = false
    },

    async confirmData() {
      this.loading = true
      try {
        await this.confirmDataRequest(this.token)
      } catch (err) {
        this.error = true
      }
      this.loading = false
    }
  },
  computed: {
    operation() {
      return this.$route.params.operation
    },
    token() {
      return this.$route.query.token
    },
    title() {
      return this.$t(`privacy.confirmationViews.${this.error ? 'error' : this.loading ? 'loading' : this.operation}.title`)
    },
    text() {
      return this.$t(`privacy.confirmationViews.${this.error ? 'error' : this.loading ? 'loading' : this.operation}.text`)
    }
  },
  async created() {
    if (this.operation === 'delete') {
      this.confirmDelete()
    } else if (this.operation === 'request') {
      this.confirmData()
    }
  }
}
</script>

<style lang="scss" scoped>
  .email-confirmation {
    width: 60%;
    height: 100vh;
  }
</style>
