<template>
  <header class="layout-default__header header-alert">
  <div class="layout-default__header__main__container header-alert-container">
      <p class="navbar-alert-text">{{alert.text}}</p>
      <button class="navbar-alert-button" @click="cancelSchedule">{{alert.cancel}}</button>
  </div>
  </header>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'NavbarAlert',
  computed: {
    alert() {
      return this.$t('navbar.alert')
    }
  },
  methods: {
    ...mapActions({
      cancelScheduleRequest: 'privacy/cancelSchedule',
      getPrivacyState: 'privacy/privacyState'
    }),

    async cancelSchedule() {
      try {
        await this.cancelScheduleRequest()
        await this.getPrivacyState()
      } catch (err) {
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/core/settings';
@import '../styles/core/tools';
.header-alert {
    background-color: map-get($colors-custom, 'red-light');
    height: 40px;
    display: flex;
    text-align: center;
    justify-content: center;
}
.header-alert-container {
  display: flex;
  align-items: center;
}
.navbar-alert-text {
    color: #ffffff;
    font: {
        weight: bold;
    }
    margin-right: 1rem;
}
.navbar-alert-button {
    color: #ffffff;
    font: {
        weight: bold;
    }
    margin-left: 1rem;
    background-color: transparent;
    border: 2px solid #ffffff;
    border-radius: 6px;
    padding: 4px;
}
</style>
