import { IdentityProvider, EmailCredentialType } from 'faster-account-sdk-js'
import account from '@/faster/account'

const state = {
  authenticated: false,
  userSession: null
}

const getters = {
  getUserSessionToken() {
    let userSession = state.userSession
    if (userSession == null || userSession.expiresAt < Date.now()) {
      return null
    }

    return userSession.token
  }
}

const actions = new function() {
  this.createUserSession = async ({ commit }) => {
    try {
      const userSession = await account.userSession.create()
      commit('SET_USER_SESSION', userSession)
    } catch (err) {
      commit('CLEAR_USER_SESSION')
    }

    return state.userSession
  }

  this.signIn = async (context, { email, password }) => {
    try {
      await account.authSession.create(IdentityProvider.FASTER, EmailCredentialType.PASSWORD, email, password)
      return await this.createUserSession(context)
    } catch (err) {
      throw new Error(err.message)
    }
  }

  this.signOut = async ({ commit }) => {
    try {
      await account.auth.signOut()
      commit('CLEAR_USER_SESSION')
    } catch (err) {
      throw new Error(`Could not Sign Out: ${err.message}`)
    }
  }
}()

const mutations = {
  CLEAR_USER_SESSION(state) {
    state.userSession = null
    state.authenticated = false
  },
  SET_USER_SESSION(state, { userId, userToken, expiresAt }) {
    state.userSession = { userId: userId, token: userToken, expiresAt }
    state.authenticated = true
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
