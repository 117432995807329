<template>
  <div id="app">
    <transition name="fade">
      <router-view />
    </transition>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import './components/svg'

export default {
  name: 'app',
  methods: {
    ...mapActions({ createUserSession: 'authentication/createUserSession' })
  },
  metaInfo() {
    const description = this.$t('description')
    return {
      meta: [
        { name: 'description', content: description },
        { property: 'og:description', content: description }
      ]
    }
  },
  async created() {
    await this.createUserSession()
  }
}
</script>

<style lang="scss">
@import '~@/styles/main.scss';
#app {
  overflow: hidden;
}

.fade-enter-active, .fade-leave-active {
  transition: transform 0.4s ease-out;
  transform-origin: center;
  transform-box: view-box;
}

.fade-enter {
  transform: scale(1.03);
}

.fade-leave-active {
  opacity: 0;
  display: none;
}
</style>
