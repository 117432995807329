<template>
  <div class="layout-default">
    <Navbar />
    <NavbarAlert v-if="privacyState === 'CONFIRMED'"/>
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Navbar from '@/components/Navbar.vue'
import NavbarAlert from '../components/NavbarAlert.vue'
export default {
  name: 'Home',
  components: {
    Navbar,
    NavbarAlert
  },
  computed: {
    ...mapState('privacy', { privacyState: store => store.privacyState }),
    ...mapState('authentication', { authenticated: store => store.authenticated })
  }
}
</script>
