<template>
  <div class="layout-default__body layout-default__body--privacy">
    <main class="layout-default__body__main__container layout-default__body__main__container--privacy">
      <img src="@/assets/privacy.jpg" class="banner"/>
      <div class="text_area">
        <div class="text_area__item">
          <h1 class="text_area__title" v-t="'privacy.info.title'"/>
        </div>
        <div class="text_area__item">
          <p class="text_area__text" v-t="'privacy.info.text'"/>
        </div>
      </div>
      <div class="divider" ></div>
      <div class="text_area">
        <div class="text_area__item">
          <h1 class="text_area__title">FAQ</h1>
        </div>

        <div class="stacked-list">
          <ul class="stacked-list__items">
            <stacked-item
            v-for="(faqIssue, index) in faqIssues"
            :key="index"
            :item="faqIssue"
            :index="index"
            :action="faqActions[faqIssue.id]"/>
          </ul>
        </div>
      </div>
    </main>

    <!-- modals -->

    <!-- delete -->
    <privacy-modal
      v-show="warnModals.delete"
      :close="closeModals"
      :confirm="() => {openCaptchaModal('delete')}"
      :title="modals.delete.title"
      :closeText="modals.closeText"
      :confirmText="modals.delete.buttonText"
      :text="modals.delete.warning"
      :showCancelButton="true"
      :loading="loading"
    />
    <privacy-modal
      v-show="emailModals.delete"
      :close="closeModals"
      :confirm="closeModals"
      :title="modals.delete.title"
      :closeText="modals.closeText"
      :confirmText="modals.confirmText"
      :text="modals.delete.email"
      :showCancelButton="false"
      :loading="loading"
    />
    <captcha-modal
      v-show="captchaModals.delete"
      :close="closeModals"
      :title="modals.delete.title"
      @success="() => {captchaSucess('delete')}"
      @error="openErrorModal"
    />

    <!-- revoke -->
    <privacy-modal
      v-show="warnModals.revoke"
      :close="closeModals"
      :confirm="() => {openCaptchaModal('revoke')}"
      :title="modals.revoke.title"
      :closeText="modals.closeText"
      :confirmText="modals.revoke.buttonText"
      :text="modals.revoke.warning"
      :showCancelButton="true"
      :loading="loading"
    />
    <privacy-modal
      v-show="emailModals.revoke"
      :close="closeModals"
      :confirm="closeModals"
      :title="modals.revoke.title"
      :closeText="modals.closeText"
      :confirmText="modals.confirmText"
      :text="modals.revoke.email"
      :showCancelButton="false"
      :loading="loading"
    />
    <captcha-modal
      v-show="captchaModals.revoke"
      :close="closeModals"
      :title="modals.revoke.title"
      @success="() => {captchaSucess('revoke')}"
      @error="openErrorModal"
    />

    <!-- request -->
    <privacy-modal
      v-show="emailModals.request"
      :close="closeModals"
      :confirm="closeModals"
      :title="modals.request.title"
      :closeText="modals.closeText"
      :confirmText="modals.confirmText"
      :text="modals.request.email"
      :showCancelButton="false"
      :loading="loading"
    />
    <captcha-modal
      v-show="captchaModals.request"
      :close="closeModals"
      :title="modals.request.title"
      @success="() => {captchaSucess('request')}"
      @error="openErrorModal"
    />

    <!-- error -->
    <privacy-modal
      v-show="errorModal"
      :close="closeModals"
      :confirm="closeModals"
      :title="modals.error.title"
      :closeText="modals.closeText"
      :confirmText="modals.confirmText"
      :text="modals.error.text"
      :showCancelButton="false"
      :loading="loading"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import PrivacyModal from '../components/PrivacyModal.vue'
import StackedItem from '../components/StackedItem.vue'
import CaptchaModal from '../components/CaptchaModal.vue'

export default {
  components: { StackedItem, PrivacyModal, CaptchaModal },
  name: 'Privacy',
  data: () => ({
    captchaModals: {
      delete: false,
      revoke: false,
      request: false
    },
    warnModals: {
      delete: false,
      revoke: false
    },
    emailModals: {
      delete: false,
      revoke: false,
      request: false
    },
    errorModal: false,
    loading: false
  }),
  methods: {
    ...mapActions({
      scheduleDeleteRequest: 'privacy/scheduleDelete',
      getPrivacyState: 'privacy/privacyState',
      cancelScheduleRequest: 'privacy/cancelSchedule',
      requestDataRequest: 'privacy/requestData'
    }),

    async scheduleDelete() {
      this.loading = true
      try {
        await this.scheduleDeleteRequest()
        await this.getPrivacyState()
      } catch (err) {
        this.openErrorModal()
      }
      this.loading = false
    },

    async cancelSchedule() {
      try {
        await this.cancelScheduleRequest()
        await this.getPrivacyState()
      } catch (err) {
        this.openErrorModal()
      }
    },

    async requestData() {
      this.loading = true
      try {
        await this.requestDataRequest()
      } catch (err) {
        this.openErrorModal()
      }
      this.loading = false
    },

    openWarningModal(type) {
      this.closeModals()
      this.warnModals[type] = true
    },

    openCaptchaModal(type) {
      this.closeModals()
      this.captchaModals[type] = true
    },

    openEmailModal(type) {
      this.closeModals()
      this.emailModals[type] = true
    },

    openErrorModal() {
      this.closeModals()
      this.errorModal = true
    },

    async captchaSucess(type) {
      this.openEmailModal(type)
      if (type === 'delete' || type === 'revoke') {
        await this.scheduleDelete()
      } else if (type === 'request') {
        await this.requestData()
      }
    },

    closeModals() {
      this.warnModals.delete = false
      this.warnModals.revoke = false
      this.captchaModals.delete = false
      this.captchaModals.revoke = false
      this.captchaModals.request = false
      this.emailModals.delete = false
      this.emailModals.revoke = false
      this.emailModals.request = false
      this.errorModal = false
    }
  },
  computed: {
    ...mapState('privacy', { privacyState: store => store.privacyState }),

    faqIssues() {
      const issues = this.$t('privacy.faq.issues')
      if (this.privacyState === 'CONFIRMED') {
        return issues.map(issue => {
          let newIssue = { ...issue }
          if ('email_confirmed_content' in newIssue) {
            newIssue.content = newIssue.email_confirmed_content
          }
          return newIssue
        })
      }
      if (this.privacyState === 'EMAIL_SENT') {
        return issues.map(issue => {
          let newIssue = { ...issue }
          if ('email_sent_content' in newIssue) {
            newIssue.content = newIssue.email_sent_content
          }
          return newIssue
        })
      }
      return issues
    },

    deleteAction() {
      if (this.privacyState === 'CONFIRMED') {
        return this.cancelSchedule
      }
      return () => { this.openWarningModal('delete') }
    },

    revokeAction() {
      if (this.privacyState === 'CONFIRMED') {
        return this.cancelSchedule
      }
      return () => { this.openWarningModal('revoke') }
    },

    modals() {
      return this.$t('privacy.modals')
    },

    faqActions() {
      return {
        get_personal_data: () => { this.openCaptchaModal('request') },
        revoke: this.revokeAction,
        delete: this.deleteAction
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/core/settings';

.layout-default__body {
  .banner {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }

  .text_area {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-top: 2rem;
    margin-bottom: 1rem;

    &__item {
      margin-bottom: 1rem;
    }
    &__title {
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 28px;
      color: #494262;
    }
    &__text {
      font-style: normal;
      font-weight: normal;
      font-size: 1.1rem;
      line-height: 24px;
      color: #494262;
    }
  }

  .divider {
    background-color: #C4C4C4;
    height: 2px;
  }
}

.stacked-list {
  $parent: &;
}
</style>
