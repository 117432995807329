<template>
  <div class="forgot-password">
    <Background/>
    <boxGeneric borderless :title="title" :subtitle="subtitle" :state="state">
      <template v-slot:default>
        <form @submit.prevent="sendForgotPassword">
          <div class="forms-container">
            <div class="input-container">
              <PKInputText
                v-model="key"
                :label-name="'passwordForgot.labels.key'"
                :autofocus="true"
                :errorText="'passwordForgot.errors.invalidKey'"
                :parentValidation="isInputValid"/>
            </div>
            <div class="button-container">
              <PKButton :name="'passwordForgot.buttons.send'" :loading="loading" :disabled="!isInputValid"/>
              <div v-if="!isInputValid" class="button-overlay"></div>
            </div>
          </div>
        </form>
      </template>
    </boxGeneric>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Background from '@/components/Background.vue'
import PKButton from '@/components/PKButton.vue'
import BoxGeneric from '@/components/BoxGeneric.vue'
import PKInputText from '@/components/PKInputText.vue'

const subtitles = {
  default: 'passwordForgot.subtitles.default',
  success: 'passwordForgot.subtitles.success',
  error: {
    'NICKNAME_NOT_FOUND': 'passwordForgot.subtitles.error.nicknameNotFound',
    'EMAIL_NOT_FOUND': 'passwordForgot.subtitles.error.emailNotFound',
    'NO_EMAIL_ASSOCIATED': 'passwordForgot.subtitles.error.noEmailAssociated',
    default: 'passwordForgot.subtitles.error.default'
  }
}

const titles = {
  default: 'passwordForgot.titles.default',
  success: 'passwordForgot.titles.success',
  error: 'passwordForgot.titles.error'
}

export default {
  name: 'passwordForgot',

  components: {
    Background,
    PKButton,
    PKInputText,
    BoxGeneric
  },

  data() {
    return {
      key: '',
      state: {
        isSuccess: false,
        isError: false
      },
      title: titles.default,
      subtitle: subtitles.default,
      loading: false
    }
  },

  methods: {
    ...mapActions({
      forgotPassword: 'password/forgot'
    }),
    async sendForgotPassword() {
      this.loading = true
      this.state.isError = false

      try {
        await this.forgotPassword({ key: this.key })
        this.state.isSuccess = true
        this.title = titles.success
        this.subtitle = subtitles.success
      } catch (err) {
        this.state.isError = true
        this.title = titles.error
        this.subtitle = subtitles.error[err.message] || subtitles.error['default']
      }

      this.loading = false
    }
  },

  computed: {
    isInputValid: function() {
      const emailRegex = /\S+@\S+\.\S+/
      const nicknameRegex = /^[a-zA-Z][a-zA-Z0-9]{3,19}#[0-9]{3,5}$/

      const key = String(this.key)
      return emailRegex.test(key.toLowerCase()) || nicknameRegex.test(key)
    }
  }
}
</script>

<style lang="scss">
@import '../styles/core/settings';
@import '../styles/core/tools';

.forgot-password {
  .box-form {
    max-width: 500px;
    margin: 50px auto;
    width: 100%;
  }

  .button {
    margin-top: 0;
  }

  .forms-container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  .input-container {
    flex-grow: 1;
  }

  .button-container {
    position: relative;
    width: 100%;
    margin: 20px 0 0 0;
  }

  .button-overlay {
    position:absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
  }

  @include media(map-get($breakpoints, 'medium')) {
    @media (orientation: portrait) {
      .box-form {
        margin-top: 0;
        padding: 25px 30px;
        background: none;

        .button-container {
          margin-top: 10px;
        }
      }

      .box-form__title {
        font-size: 26px;
      }

      .box-form__subtitle {
        font-size: 14px;
        margin-top: 16px;
        padding: 0 20px;
      }
    }
  }

  @include media(map-get($breakpoints, 'small'), 'max-height') {
    @media (orientation: landscape) {
    .box-form {
      margin-top: 0;
      max-width: 610px;
      padding: 25px 30px;
      background: none;
    }

    .forms-container {
      flex-direction: row;
      margin-top: 24px;
    }

    .button-container {
      align-self: flex-start;
      width: 150px;
      margin: 23px 0 0 25px;
    }

    .button {
      height: 44px;
    }
  }
  }
}

</style>
