export default {
  description: 'Welcome to your virtual world! Crafty Lands is all about fun: create your avatar, build your house, meet friends, and go on new adventures.',
  passwordReset: {
    titles: {
      default: 'New password',
      success: 'The new password was set successfully!',
      error: 'Something went wrong'
    },
    subtitles: {
      default: 'Please, enter a new password for your Crafty Lands account.',
      success: 'The new password was set successfully!',
      error: 'It looks like some error has occurred. Try again please.'
    },
    placeholder: '(6 to 15 characters)',
    labels: {
      newPassword: 'New Password',
      confirmPassword: 'Confirm password'
    },
    buttons: {
      save: 'Save'
    },
    errors: {
      size: 'Please enter a valid password that’s 6 to 15 characters',
      mismatch: 'These passwords don\'t match. Try again please.'
    }
  },
  passwordForgot: {
    titles: {
      default: 'Forgot your password?',
      success: 'Request received',
      error: 'Something went wrong'
    },
    subtitles: {
      default: 'Enter your e-mail address or your username and we will send you instructions on how to change your password',
      success: 'Please, check your inbox and follow the instructions.',
      error: {
        nicknameNotFound: 'Username not found',
        emailNotFound: 'Email not found',
        noEmailAssociated: `This account doesn't have a registered email`,
        default: 'It looks like some error has occurred. Try again please.'
      }
    },
    buttons: {
      send: 'Send'
    },
    labels: {
      key: 'e-mail or username'
    },
    errors: {
      invalidKey: 'Please, enter a valid e-mail or username.'
    }
  },
  login: {
    username: 'Username',
    password: 'Password',
    email: 'Email or username',
    forgotPassword: 'Forgot password',
    backToLogin: 'back to login',
    didNotGetEmail: 'Did not get the email?',
    titles: {
      forgotPassword: 'Forgot password?'
    },
    subtitles: {
      enterPassword: `Enter your e-mail address or your username and we will send you instructions on how to change your password`,
      emailSent: 'An email has been sent. Please check it :)'
    },
    buttons: {
      login: 'Login',
      send: 'Send'
    },
    policies: {
      privacy: 'Privacy Policy',
      terms: 'Terms of Use'
    }
  },
  general: {
    success: 'Success',
    tryAgain: 'Try again'
  },
  navbar: {
    menu: {
      store: 'Store',
      editAvatar: 'Edit Avatar'
    },
    dropdown: {
      logout: 'Logout'
    },
    alert: {
      text: 'You have requested to revoke your consent; your account will be deleted within 14 days',
      cancel: 'CANCEL'
    }
  },
  inventory: {
    buttons: {
      save: 'SAVE'
    }
  },
  screenshot: {
    share: 'Share to Facebook'
  },

  privacy: {
    info: {
      title: 'Privacy Center',
      text: 'Personal data is data that can be used to identify a person. Our team at Crafty Lands takes the privacy of your personal information very seriously; we comply with all legal requirements and have technical and organizational policies in place that ensure this information is protected.'
    },
    faq: {
      title: 'FAQ',
      issues: [
        {
          id: 'treat_personal_data',
          title: 'Does Crafty Lands use your personal data?',
          content: [
            'When you create an account at Crafty Lands, you add your email and nickname to our database; when you play, your access data is saved, and billing information is stored when you make a purchase.'
          ],
          'isExpanded': false,
          'isHome': true,
          'isActive': true
        },
        {
          id: 'get_personal_data',
          title: 'What personal data of yours does Crafty Lands have?',
          content: [
            'At Crafty Lands we collect your email address when you create an account and your access data (IP, date, and time) to improve your experience. At any time you can ask us to send you a file with all your personal data that we have collected. You will receive it via email within 15 days.',
            { type: 'action', value: 'Click here to download your personal data.' }
          ],
          'isExpanded': false,
          'isHome': true,
          'isActive': true
        },
        {
          id: 'att_data',
          title: 'Do you know how to update your personal data with us?',
          content: [
            'To update your data, click on the link below. You will receive an e-mail with instructions on how to proceed.',
            { type: 'link', value: 'Click here to update your personal data.', href: 'https://craftylands.zendesk.com/hc/pt-br/requests/new' }
          ],
          'isExpanded': false,
          'isHome': true,
          'isActive': true
        },
        {
          id: 'privacy_policy',
          title: 'Do you know with whom your personal data is shared?',
          content: [
            'In order to provide the best possible game, we rely on a network of partners that use some data to: optimize our servers, improve in-game experience, or present important safety information. We explain this in detail in our Privacy Policy.',
            { type: 'link', value: 'Privacy Policy', href: 'https://policies.craftylands.afterverse.com/privacy/2.0' }
          ],
          'isExpanded': false,
          'isHome': true,
          'isActive': true
        },
        {
          id: 'revoke',
          title: 'Can you withdraw your consent for the use of your personal data?',
          content: [
            'Some of the data is collected with your consent at the time your account is created and you are given access to the game. You can withdraw this consent at any time. Note: Upon withdrawing your consent, your account will be permanently deleted in 14 days and you will lose all items purchased within the game forever.',
            { type: 'action', value: 'Click here to withdraw your consent.' }
          ],
          email_sent_content: [
            'Some of the data is collected with your consent at the time your account is created and you are given access to the game. You can withdraw this consent at any time. Note: Upon withdrawing your consent, your account will be permanently deleted in 14 days and you will lose all items purchased within the game forever.',
            { type: 'warn', value: 'We\'ve sent an email with a link to confirm your request. If you can\'t find the email, check your spam/junk mail box or click below to resend.' },
            { type: 'action', value: 'Resend e-mail' }
          ],
          email_confirmed_content: [
            'Some of the data is collected with your consent at the time your account is created and you are given access to the game. You can withdraw this consent at any time. Note: Upon withdrawing your consent, your account will be permanently deleted in 14 days and you will lose all items purchased within the game forever.',
            { type: 'warn', value: 'You have requested to revoke your consent; your account will be deleted within 14 days' },
            { type: 'action', value: 'Cancel' }
          ],
          'isExpanded': false,
          'isHome': true,
          'isActive': true
        },
        {
          id: 'delete',
          title: 'Do you want to delete your personal data from our database?',
          content: [
            'You can request that all your personal data be deleted from our database at any time. Note: Upon deleting your data from our database, your account will be permanently deleted in 14 days and you will lose all items purchased within the game forever.',
            { type: 'action', value: 'Click here to delete your personal data.' }
          ],
          email_sent_content: [
            'You can request that all your personal data be deleted from our database at any time. Note: Upon deleting your data from our database, your account will be permanently deleted in 14 days and you will lose all items purchased within the game forever.',
            { type: 'warn', value: 'We\'ve sent an email with a link to confirm your request. If you can\'t find the email, check your spam/junk mail box or click below to resend.' },
            { type: 'action', value: 'Resend e-mail' }
          ],
          email_confirmed_content: [
            'You can request that all your personal data be deleted from our database at any time. Note: Upon deleting your data from our database, your account will be permanently deleted in 14 days and you will lose all items purchased within the game forever.',
            { type: 'warn', value: 'You have requested to revoke your consent; your account will be deleted within 14 days' },
            { type: 'action', value: 'Cancel' }
          ],
          'isExpanded': false,
          'isHome': true,
          'isActive': true
        },
        {
          id: 'ads',
          title: 'Personalized Ads',
          content: [
            'In order for Crafty Lands to always be an organization and team dedicated to innovation and improvement, part of game revenue comes from ads that are personalized and relevant to each player. You can choose to not receive these ads and instead receive only ads for the general public.'
          ],
          'isExpanded': false,
          'isHome': true,
          'isActive': true
        },
        {
          id: 'terms&privacy',
          title: 'Terms of Use and Privacy Policy',
          content: [
            'Our Terms of Use and Privacy policy are available for you to review at any time. You will receive notice of any changes to how your data is used.',
            { type: 'link', value: 'Terms of Use', href: 'https://policies.craftylands.afterverse.com/en/terms/2.0' },
            { type: 'link', value: 'Privacy Policy', href: 'https://policies.craftylands.afterverse.com/privacy/2.0' }
          ],
          'isExpanded': false,
          'isHome': true,
          'isActive': true
        }
      ]
    },
    modals: {
      closeText: 'CANCEL',
      confirmText: 'OK',
      delete: {
        title: 'Delete personal data',
        warning: 'Are you sure you want to delete your personal data? This action will delete your account, and you will lose all progress and in-game purchases.',
        email: 'We\'ve sent an email with a link to confirm your request. If you can\'t find the email, check your spam/junk mail box or click below to resend.',
        buttonText: 'DELETE'
      },
      revoke: {
        title: 'Revoke consent',
        warning: 'Are you sure you want to revoke your consent? This action will delete your account and you will lose all progress and in-game purchases.',
        email: 'We\'ve sent an email with a link to confirm your request. If you can\'t find the email, check your spam/junk mail box or click below to resend.',
        buttonText: 'REVOKE'
      },
      request: {
        title: 'Request personal data',
        email: 'We\'ve sent an email with a link to confirm your request. If you can\'t find the email, check your spam/junk mail box or click below to resend.'
      },
      error: {
        title: 'Oops',
        text: 'Looks like something went wrong, please try again'
      },
      captcha: {
        text: 'Click the button below to make sure you are not a robot:',
        label: 'I\'m not a robot'
      }
    },
    confirmationViews: {
      delete: {
        title: 'Data deletion requested',
        text: 'Your data will be deleted within 14 days; this action will permanently delete your account. If you want to revert this action, go to craftylands.afterverse.com'
      },
      request: {
        title: 'Data requested',
        text: 'Your data has been requested; within 14 days, we will send you an e-mail with the data. If you have any questions, go to craftylands.afterverse.com'
      },
      error: {
        title: 'Oops, it looks like your request has expired',
        text: 'We were unable to complete the operation because your request has expired, you can request the operation again through the privacy center'
      },
      loading: {
        title: 'Processing request',
        text: 'We are processing your request, please wait'
      }
    }
  }
}
